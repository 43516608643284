import careerListQuery from '../career/listQuery';
import investorRelationsListQuery from '../investorRelation/listQuery';
import accordionQuery from '../modules/accordionQuery';
import seoQuery from '../modules/seo';
import newsListQuery from '../news/listQuery';
import pageListQuery from '../page/listQuery';

const content = `
defined(ctas) => {ctas[] {
  ...,
  'route': route->elements.slug.current,
}},
defined(video) => {video {
  ...,
  asset->
}},
defined(image) => {image {
  ...,
  "url": asset->url
}},
defined(logos) => {logos[] {
  ...,
  asset->
}},
defined(images) => {images[] {
  ...,
  asset->
}},
defined(figure) => {figure {
  ...,
  asset->
}},
defined(backgroundImage) => {backgroundImage {
  ...,
  asset->
}},
defined(backgroundImages) => {backgroundImages[] {
  ...,
  asset->
}},
defined(backgroundVideo) => {backgroundVideo {
  ...,
  asset->
}},
defined(backgroundVideoMobile) => {backgroundVideoMobile {
  ...,
  asset->
}},
_type == 'linkToPageBanner' => {
  links[] {
    ...,
    "slug": ref->elements.slug.current,
  }
},
_type == 'investorRelationsList' => {
  ${investorRelationsListQuery}
},
_type == 'jobList' => {
  ${careerListQuery}
},
_type == 'newsList' => {
  ${newsListQuery}
},
_type == 'pageList' => {
  ${pageListQuery}
},
_type == "cards" => {
  cards[] {
    _key,
    heading,
    text,
    'slug': link->elements.slug.current,
    image {
      ...,
      asset->
    }
  }
},
_type == 'casesSelected' => {
  cases[]-> {
    ...elements {
      ...,
      "imageUrl": mainImage.asset->url,
    }
  }
},
_type == 'accordion' => {
  ${accordionQuery}
},
_type == 'marquee' => {
  lead,
  logos[] {
    "imageUrl": asset->url,
  }
},
_type == 'tabs' => {
  ...,
  tabs[] {
    ...,
    content {
      ...,
      image {
        ...,
        "url": asset->url
      },
    },
    tabs[] {
      ...,
      content {
        ...,
        image {
          ...,
          "url": asset->url
        },
      },
    }
  }
},
_type == 'downloadablesBanner' => {
  "asset": file.asset->,
},
_type == 'ctaBanner' => {
  "ctaTitle": cta.title,
  "ctaInternal": cta.route->elements.slug.current,
  "ctaExternal": cta.link,
},
_type == 'twoColumnTextBanner' => {
  rows[] {
    ...,
    rightColumn[] {
      ...,
      _type == 'cta' => {
        ...,
        "ctaInternal": route->elements.slug.current,
        "ctaExternal": link,
      },
    },
  },
},
_type == 'testimonialsBanner' => {
  testimonials[] {
    ...,
    logo {
      ...,
      asset->
    }
  }
},
_type == 'latestNews' => {
  "latestNews": *[_type=='news'] | order(dateTime(elements.publishedAt) desc) {
    ...elements {
      "uri": slug.current,
      title,
      description,
      publishedAt
    },
    _createdAt
  }[0..3],
},
_type == 'employeeList' => {
  ...,
  departmentFilters[]->,
  "employees": *[_type=='employee'] {
    ...,
    "priority": coalesce(priority, 0),
    image {
      ...,
      hotspot,
      crop,
      asset->,
    },
    departments[]->
  }[],
},
_type == 'highlights' => {
  list[] {
    ...,
    image {
      ...,
      "url": asset->url
    },
    link {
      ...,
      "route": route->elements.slug.current,
    }
  }
},
_type == 'imageBanner' => {
  ...,
},
_type == 'defaultText' => {
  ...,
  text[] {
    ...,
    imageGallery[] {
      ...,
      'url': asset->url,
    }
  }
}`;

export const pageQuery = `
  *[_type == "page" && elements.slug.current == $slug] {
    ...elements {
      ...,
      seo {
        ${seoQuery}
      },
      content[] {
        ...,
        ${content},
        _type == 'globalComponentModule' => {
          ...,
          ...component-> {
            ...,
            content[] {
              ...,
              ${content}
            }
          }
        }
      }
    }
  }
[0]`;

export default module.exports = pageQuery.replace(/ +/g, '');
