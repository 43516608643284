import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

const PageHead = ({ data }: {
  data?: {
    title?: string;
    lead?: any;
    description?: string;
    imageUrl?: string;
    content: [any];
    seo?: {
      title?: string;
      description?: string;
      image?: {
        url: string;
      };
    };
  };
}): JSX.Element => {
  const router = useRouter();

  const resolveTitle = (): string => {
    if (data?.seo?.title) {
      return data.seo.title;
    }

    if (data?.title) {
      return data.title;
    }

    return 'Marketer';
  };

  const resolveDescription = (): string => {
    if (data?.seo?.description) {
      return data.seo.description;
    }

    if (data?.description) {
      return data.description;
    }

    return "Tomorrow's marketing and sales platform for real estate.";
  };

  const resolveImageUrl = (): string => {
    if (data?.seo?.image) {
      return data.seo.image.url;
    }

    const f = data?.content?.find((c) => c._type === 'fullscreenImage');

    if (f) {
      return f.imageUrl;
    }

    return null;
  };

  return (
    <Head>
      <title>{`Marketer - ${data.title}`}</title>
      <meta name="description" content={resolveDescription()} />
      <meta property="og:title" content={resolveTitle()} />
      <meta property="og:description" content={resolveDescription()} />
      <meta property="og:image" content={resolveImageUrl()} />
      <meta property="og:url" content={process.env.baseUrl + router.asPath} />
    </Head>
  );
};

export default PageHead;
