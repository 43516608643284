export const query = /* groq */ `
  categoryFilters[]->{
    title
  },
  selectedPages[]->{
    _createdAt,
    order,
    ...elements {
      categories[]->{
        title,
      },
      slug,
      title,
      "description": seo.description, 
      "image": seo.image.asset->url,
    }
  },
  "limit": coalesce(limit, null),
  "allPages": *[_type=='page' && elements.hidden!=true] | order(dateTime(elements.publishedAt) desc) {
    _createdAt,
    order,
    ...elements {
      categories[]->{
        title,
      },
      slug,
      title,
      "description": seo.description, 
      "image": seo.image.asset->url,
    }
  }
`;

export default query.replace(/ +/g, '');
