import React from 'react';

type SiteConfigProviderProps = {
  siteConfig: any;
  children: React.ReactNode;
};

export const SiteConfigContext = React.createContext<any | undefined>(undefined);

export function SiteConfigProvider({ siteConfig, children }: SiteConfigProviderProps): any {
  return <SiteConfigContext.Provider value={siteConfig}>{children}</SiteConfigContext.Provider>;
}

export function useSiteConfig(): any {
  const context = React.useContext(SiteConfigContext);

  if (context === undefined) {
    throw new Error('useSiteConfig must be used within a SiteConfigProvider');
  }

  return context;
}
