import { useRouter } from 'next/router';
import React from 'react';

import { SiteConfigProvider } from '@/components/context/siteConfig';
import { ThemeProvider, Themes } from '@/components/context/theme';
import FourOhFour from '@/components/FourOhFour';
import Layout from '@/components/layout';
import PageHead from '@/components/plugs/PageHead';
import PlugsResolver from '@/components/plugs/PlugResolver';
import pageQuery from '@/utils/queries/page/pageQuery';
import pagesPathsQuery from '@/utils/queries/page/pagesPathsQuery';
import siteConfigQuery from '@/utils/queries/siteConfigQuery';
import { usePreviewSubscription } from '@/utils/sanity/sanity';
import { getClient, sanityClient } from '@/utils/sanity/sanity.server';

/**
 * [[...slug]] means this is a catch-all route.
 * This means that it needs to handle no slug at all (home)
 * and other pages that have slugs.
 *
 * getStaticPaths: Return an array of pages that will be generated at build time.
 * getStaticProps: Fetch data for given path at build time.
 * usePreviewSubscription: If preview, subscribe to Sanity.
 *
 */

interface Props {
  data: any;
  preview: boolean;
  siteConfig: any;
}

function filterDataToSingleItem(data: any, preview: boolean): any {
  if (!Array.isArray(data)) {
    return data;
  }

  if (data.length === 1) {
    return data[0];
  }

  if (preview) {
    return data.find((item) => item._id.startsWith(`drafts.`)) || data[0];
  }

  return data[0];
}

const Page = ({ data, preview, siteConfig }: Props): JSX.Element => {
  const router = useRouter();
  const slug = data?.slug?.current || '';

  const { data: previewData } = usePreviewSubscription(pageQuery, {
    params: { slug },
    initialData: data,
    enabled: preview,
  });

  const page = filterDataToSingleItem(previewData, preview);

  if (!router.isFallback && !slug) {
    return (
      <ThemeProvider theme={Themes.DARK}>
        <Layout siteConfig={siteConfig}>
          <FourOhFour />
        </Layout>
      </ThemeProvider>
    );
  }

  return (
    <SiteConfigProvider siteConfig={siteConfig}>
      <ThemeProvider theme={data.menuTheme}>
        <Layout siteConfig={siteConfig} isLandingpage={data.isLandingpage}>
          <PageHead data={page} />
          <PlugsResolver plugs={page?.content} />
        </Layout>
      </ThemeProvider>
    </SiteConfigProvider>
  );
};

export async function getStaticPaths(): Promise<Record<string, unknown>> {
  const data = await sanityClient.fetch(pagesPathsQuery);
  const paths = data.map((route, key) => {
    return {
      params: { id: key, slug: [route.elements.slug.current.toString()] },
    };
  });
  return { paths, fallback: 'blocking' };
}

interface getStaticProps {
  params: any;
  preview: boolean;
}

export async function getStaticProps({
  params,
  preview = false,
}: getStaticProps): Promise<Record<string, unknown>> {
  const { slug } = params;

  const sanitySlug = Array.isArray(slug) ? slug.join('/') : 'home';
  const data = await getClient(preview).fetch(pageQuery, { slug: sanitySlug });

  if (!data) {
    return {
      notFound: true,
    };
  }

  const page = filterDataToSingleItem(data, preview);

  const siteConfig = await getClient(preview).fetch(siteConfigQuery, { slug: sanitySlug });

  return {
    revalidate: 60,
    props: {
      data: page,
      siteConfig,
      preview,
    },
  };
}

export default Page;
